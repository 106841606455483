export const useParseRoute = (route) => {
    const { $i18n } = useNuxtApp();
    const locale = unref($i18n.locale);

    const path = unref(route?.path);
    let uri = path.slice(1);

    // Trim trailing slash from the URI, as it will return a 404 from the graphql endpoint.
    if (uri.endsWith('/')) {
        uri = uri.slice(0, uri.length - 1);
    }

    if (uri.startsWith(`${locale}/`)) {
        uri = uri.slice(3);
    }

    return uri;
};
